import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSelectModule } from '@angular/material/select';
import { MatChipsModule } from '@angular/material/chips';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatCardModule } from '@angular/material/card';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { NgxSliderModule } from 'ngx-slider-v2';
import { NgxSelectModule } from 'ngx-select-ex';
import { QuillModule } from 'ngx-quill';
import { SharedModule } from '../shared/shared.module';
import { FileUploadModule } from '../shared/file-upload';
import { LayoutModule } from '../layout/layout.module';
import { AnnouncementModule } from '../routes/announcement/announcement.module';
import {
    adminState,
    AnnouncementsManagementComponent,
    ClientProfileComponent,
    ClientProfileService,
    ControlsConfigComponent,
    ControlsConfigListComponent,
    DataTypesConfigComponent,
    DemoToolsComponent,
    ElasticsearchIndexComponent,
    HubspotManagementComponent,
    LogsComponent,
    LogsService,
    RiskLevelsConfigComponent,
    WebhooksComponent,
} from './';
import { DemoToolsService } from './demo-tools/demo-tools.service';
import { RiskModelComponent } from './risk-model/risk-model.component';
import { AssessmentRecommendationComponent } from './recommendations/assessment-recommendation.component';
import { ElasticsearchIndexService } from './elasticsearch-index/elasticsearch-index.service';
import { WebhooksService } from './webhooks/webhooks.service';
import { DataSensitivityLevelsConfigComponent } from './client-profile/data-sensitivity-levels-config/data-sensitivity-levels-config.component';
import { ArtifactManagementComponent } from './artifact-management/artifact-management.component';
import { ArtifactManagementService } from './artifact-management/artifact-management.service';
import { LicenseManagementComponent } from './license-management/license-management.component';
import { ClientOffboardingComponent } from './client-offboarding/client-offboarding.component';
import { JwtManagementService } from './jwt-management/jwt-management.service';
import { JwtManagementComponent } from './jwt-management/jwt-management.component';
import { NotificationsComponent } from './notifications/notifications/notifications.component';
import { OrgImportComponent } from './org-import/org-import.component';
import { SecImportComponent } from './sec-import/sec-import.component';
import { AdminAnnouncementService } from './announcements/admin-announcement.service';
import { FeatureFlagManagementComponent } from './feature-flag-management/feature-flag-management.component';
import { FeatureFlagsManagementService } from './feature-flag-management/feature-flag-management.service';
import { HubspotManagementService } from './hubspot-management/hubspot-management.service';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatInputModule } from '@angular/material/input';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

@NgModule({
    imports: [
        SharedModule,
        QuillModule,
        RouterModule.forChild(adminState),
        FileUploadModule,
        NgxDatatableModule,
        NgxSliderModule,
        ReactiveFormsModule,
        NgxSelectModule,
        LayoutModule,
        AnnouncementModule,
        MatButtonModule,
        MatDatepickerModule,
        MatCheckboxModule,
        MatSlideToggleModule,
        MatSelectModule,
        MatChipsModule,
        MatFormFieldModule,
        MatCardModule,
        MatProgressBarModule,
        MatAutocompleteModule,
        MatInputModule,
        NgbModule,
    ],
    declarations: [
        LogsComponent,
        DemoToolsComponent,
        RiskModelComponent,
        AssessmentRecommendationComponent,
        ElasticsearchIndexComponent,
        ArtifactManagementComponent,
        WebhooksComponent,
        ClientProfileComponent,
        RiskLevelsConfigComponent,
        DataSensitivityLevelsConfigComponent,
        DataTypesConfigComponent,
        ControlsConfigComponent,
        LicenseManagementComponent,
        NotificationsComponent,
        ClientOffboardingComponent,
        JwtManagementComponent,
        OrgImportComponent,
        SecImportComponent,
        AnnouncementsManagementComponent,
        FeatureFlagManagementComponent,
        ControlsConfigListComponent,
        HubspotManagementComponent,
    ],
    providers: [
        LogsService,
        DemoToolsService,
        ElasticsearchIndexService,
        WebhooksService,
        ClientProfileService,
        ArtifactManagementService,
        JwtManagementService,
        AdminAnnouncementService,
        FeatureFlagsManagementService,
        HubspotManagementService,
    ],
    exports: [ReactiveFormsModule],
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class VisoAdminModule {}
