import { ChangeDetectorRef, CUSTOM_ELEMENTS_SCHEMA, NgModule, Provider } from '@angular/core';
import { AsyncPipe, CommonModule, DatePipe, PercentPipe, TitleCasePipe, UpperCasePipe } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';

import { AccordionModule } from 'ngx-bootstrap/accordion';
import { AlertModule } from 'ngx-bootstrap/alert';
import { ButtonsModule } from 'ngx-bootstrap/buttons';
import { CarouselModule } from 'ngx-bootstrap/carousel';
import { CollapseModule } from 'ngx-bootstrap/collapse';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { ModalModule } from 'ngx-bootstrap/modal';
import { PaginationModule } from 'ngx-bootstrap/pagination';
import { ProgressbarModule } from 'ngx-bootstrap/progressbar';
import { RatingModule } from 'ngx-bootstrap/rating';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { TimepickerModule } from 'ngx-bootstrap/timepicker';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { PopoverModule } from 'ngx-bootstrap/popover';
import { TypeaheadModule } from 'ngx-bootstrap/typeahead';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';

import { NowDirective } from './directives/now/now.directive';
import { AutoGrowDirective } from './directives/auto-grow/auto-grow.directive';
import { PdfExportableDirective } from './directives/pdf-exportable/pdf-exportable.directive';
import { AsShadowDirective } from './directives/as-shadow/as-shadow.directive';
import { IsNaNPipe } from './pipes/is-not-nan.pipe';
import { IsNotNaNPipe } from './pipes/is-nan.pipe';
import { PascalCasePipe } from './pipes/pascal-case.pipe';

// https://angular.io/styleguide#!#04-10
import { AccountService, AuthServerProvider, CSRFService, FileDragAndDropDirective } from './';
import { NgChartsModule } from 'ng2-charts';
import { ConvertToCsvService } from './services/convert-to-csv.service';
import { ConfirmDialogComponent } from './components/confirm-dialog/confirm-dialog/confirm-dialog.component';
import { SearchFilterService } from './services/search-filter.service';
import { CdrAsyncPipe } from './pipes/cdr-async.pipe';
import { RemoveUnderscorePipe } from './pipes/replace-underscore-with-space.pipe';
import { HoverClassDirective } from './directives/hover-class/hover-class.directive';
import { NgxDatatableDirective } from './ngx-datables/ngx-datatable.directive';
import { CardModule } from './components/card/card.module';
import { FileUploadModule } from './file-upload';
import { NgxSelectModule } from 'ngx-select-ex';
import { StatBoxComponent } from './components/stat-box/stat-box.component';
import { AppendContentDirective } from './directives/append-content/append-content.directive';
import { DynamicErrorMessageComponent } from './components/dynamic-error-message/dynamic-error-message.component';
import {
    DisableOnTrialDirective,
    TrialDisableWrapperContainerComponent,
} from './directives/disable-on-trial/disable-on-trial.directive';
import { ColorPickerComponent } from './components/color-picker/color-picker.component';
import { CollapseItemsDirective } from './directives/collapse-items/collapse-items.directive';
import { CollapsedItemsComponent } from './components/collapsed-items/collapsed-items.component';
import { UrlValidationDirective } from './validators/url-validator';
import { QuillModule } from 'ngx-quill';
import { DisableControlDirective } from './directives/disable-control/disable-control.directive';
import { OrderByPipe } from './pipes/order-by.pipe';
import { PureFilterPipe } from './pipes/pure-filter.pipe';
import { AssuranceLevelMeterModule } from './assurance-level-meter';
import { ExternalLinkModule } from './external-link';
import { DocumentFileModule } from './document-file/document-file.module';
import { AuthDirectivesModule } from './auth-directives';
import { BadgeModule } from './badge';
import { CallbackPipeModule } from './callback-pipe';
import { ToggleDisableOnActionsModule } from './toggle-disable-on-actions/toggle-disable-on-actions.module';
import { ActionNotificationModule } from './action-notification/action-notification.module';
import { ImageFallbackModule } from './image-fallback/image-fallback.module';
import { TagsModule } from './tags/tags.module';
import { CustomSelectModule } from './custom-select/custom-select.module';
import { MatButtonModule } from '@angular/material/button';
import { VisoAnchorDirective } from './directives/viso-anchor/viso-anchor.directive';
import { VisoButtonModule } from './viso-button/viso-button.module';
import { DynamicContentModule } from './dynamic-content/dynamic-content.module';
import { PopoverHandlerDirective } from './directives/popover-handler/popover-handler.directive';
import { RadioButtonGroupModule } from './radio-button-group/radio-button-group.module';
import { TrimFileExtensionPipe } from './pipes/trim-file-extension.pipe';
import { RiskUtilsModule } from './risk-utils/risk-utils.module';
import { ReplacePipeModule } from './replace-pipe/replace-pipe.module';
import { SafeHtmlPipeModule } from './safe-html-pipe/safe-html-pipe.module';
import { EnumToArrayPipeModule } from './enum-to-array-pipe/enum-to-array-pipe.module';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        NgChartsModule,
        ReactiveFormsModule,
        AccordionModule.forRoot(),
        AlertModule.forRoot(),
        ButtonsModule.forRoot(),
        CarouselModule.forRoot(),
        CollapseModule.forRoot(),
        BsDatepickerModule.forRoot(),
        BsDropdownModule.forRoot(),
        ModalModule.forRoot(),
        PaginationModule.forRoot(),
        ProgressbarModule.forRoot(),
        RatingModule.forRoot(),
        TabsModule.forRoot(),
        TimepickerModule.forRoot(),
        TooltipModule.forRoot(),
        PopoverModule.forRoot(),
        TypeaheadModule.forRoot(),
        CardModule,
        FileUploadModule,
        NgxSelectModule,
        QuillModule,
        MatButtonModule,
        AssuranceLevelMeterModule,
        ExternalLinkModule,
        DocumentFileModule,
        AuthDirectivesModule,
        BadgeModule,
        CallbackPipeModule,
        ToggleDisableOnActionsModule,
        ImageFallbackModule,
        TagsModule,
        CustomSelectModule,
        VisoButtonModule,
        DynamicContentModule,
        RadioButtonGroupModule,
        RiskUtilsModule,
        ReplacePipeModule,
        SafeHtmlPipeModule,
        EnumToArrayPipeModule,
        MatSnackBarModule,
    ],
    providers: [
        AccountService,
        CSRFService,
        AuthServerProvider,
        DatePipe,
        TitleCasePipe,
        UpperCasePipe,
        AsyncPipe,
        ChangeDetectorRef as Provider,
        PercentPipe,
        IsNaNPipe,
        IsNotNaNPipe,
        TrimFileExtensionPipe,
        PascalCasePipe,
        CdrAsyncPipe,
        ConvertToCsvService,
        SearchFilterService,
    ],
    declarations: [
        NowDirective,
        AutoGrowDirective,
        PdfExportableDirective,
        AsShadowDirective,
        HoverClassDirective,
        IsNaNPipe,
        IsNotNaNPipe,
        NgxDatatableDirective,
        TrimFileExtensionPipe,
        PascalCasePipe,
        CdrAsyncPipe,
        FileDragAndDropDirective,
        ConfirmDialogComponent,
        RemoveUnderscorePipe,
        StatBoxComponent,
        AppendContentDirective,
        DynamicErrorMessageComponent,
        DisableOnTrialDirective,
        TrialDisableWrapperContainerComponent,
        ColorPickerComponent,
        CollapseItemsDirective,
        CollapsedItemsComponent,
        UrlValidationDirective,
        DisableControlDirective,
        OrderByPipe,
        PureFilterPipe,
        VisoAnchorDirective,
        PopoverHandlerDirective,
    ],
    exports: [
        CommonModule,
        FormsModule,
        NgbModule,
        ReactiveFormsModule,
        RouterModule,
        AccordionModule,
        AlertModule,
        ButtonsModule,
        CarouselModule,
        CollapseModule,
        BsDatepickerModule,
        BsDropdownModule,
        ModalModule,
        PaginationModule,
        ProgressbarModule,
        RatingModule,
        TabsModule,
        TimepickerModule,
        TooltipModule,
        PopoverModule,
        TypeaheadModule,
        CardModule,
        NowDirective,
        AutoGrowDirective,
        PdfExportableDirective,
        AsShadowDirective,
        HoverClassDirective,
        NgxDatatableDirective,
        IsNaNPipe,
        IsNotNaNPipe,
        TrimFileExtensionPipe,
        PascalCasePipe,
        CdrAsyncPipe,
        FileDragAndDropDirective,
        ConfirmDialogComponent,
        RemoveUnderscorePipe,
        StatBoxComponent,
        DisableOnTrialDirective,
        AppendContentDirective,
        ColorPickerComponent,
        CollapseItemsDirective,
        CollapsedItemsComponent,
        UrlValidationDirective,
        DisableControlDirective,
        OrderByPipe,
        PureFilterPipe,
        AssuranceLevelMeterModule,
        ExternalLinkModule,
        DocumentFileModule,
        AuthDirectivesModule,
        BadgeModule,
        CallbackPipeModule,
        ToggleDisableOnActionsModule,
        ActionNotificationModule,
        ImageFallbackModule,
        TagsModule,
        CustomSelectModule,
        VisoAnchorDirective,
        MatButtonModule,
        CallbackPipeModule,
        VisoButtonModule,
        DynamicContentModule,
        PopoverHandlerDirective,
        RadioButtonGroupModule,
        RiskUtilsModule,
        ReplacePipeModule,
        SafeHtmlPipeModule,
        EnumToArrayPipeModule,
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class SharedModule {}
