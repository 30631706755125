<div *dynamicContent="breadcrumbsContainerToken">
    <ol class="breadcrumb breadcrumb px-0 pb-0">
        <li class="breadcrumb-item">
            <a [routerLink]="['/']">Home</a>
        </li>
        <li class="breadcrumb-item active">Relationships</li>
    </ol>
</div>
<div class="content-heading d-flex justify-content-between">
    <div>Relationships</div>
    <div class="d-flex align-items-center">
        <ng-container *jhiHasAnyAuthority="[Roles.OrgAdmin, Roles.OrgUser]">
            <app-radiobutton-group
                id="view-toggle"
                [type]="'toggle'"
                [direction]="'row'"
                [radiobuttonGroupName]="'listOrGraph'"
                [size]="'small'"
                [formControl]="listOrGraphRadioFormControl"
                class="me-3"
            >
                <ng-template radiobutton-group-item [value]="ListOrGraphOptions.List" let-forItem="forItem">
                    <label [for]="forItem">List View</label>
                </ng-template>

                <ng-template radiobutton-group-item [value]="ListOrGraphOptions.Graph" let-forItem="forItem">
                    <label [for]="forItem">Graph View</label>
                </ng-template>
            </app-radiobutton-group>
        </ng-container>
        <ng-container *jhiHasNotAuthority="[Roles.Auditor, Roles.Admin, Roles.Support]">
            <button
                id="new-relationship-btn"
                *disableAddRelationshipOnRules
                class="pull-right"
                mat-button
                visoButton="primary"
                [routerLink]="['/', { outlets: { popup: [addRelationshipRoute] } }]"
                [replaceUrl]="true"
                queryParamsHandling="merge"
                data-pw="newRelationshipButton"
            >
                Add Relationship
            </button>
        </ng-container>
    </div>
</div>

<div class="row">
    <div class="col-lg-12">
        <section class="relationships-loading" *ngIf="relationshipsLoading && !relationshipsLoaded">
            <div class="d-flex align-items-center justify-content-center">
                <div class="sk-spinner sk-spinner-pulse"></div>
            </div>
        </section>

        <section
            class="relationships-header d-flex flex-wrap row-gap-2 justify-content-between"
            *ngIf="relationshipsSelected.length === 0 && relationshipsLoaded"
        >
            <div class="relationships-count me-3">
                <ng-container *ngIf="showList$ | async">
                    {{ totalCurrentItems }} {{ areFiltersApplied && totalItems ? 'of ' + totalItems : '' }} Third Party
                    Relationships
                </ng-container>
                <ng-container *ngIf="showGraph$ | async">
                    {{ totalGraphItems }} Third Party Relationships
                </ng-container>
            </div>
            <section class="relationships-filters d-flex flex-wrap row-gap-1 align-items-center">
                <div class="clear-all-filters">
                    <button
                        *ngIf="areFiltersApplied || !!networkExposureOrgId"
                        class="me-2"
                        type="button"
                        mat-button
                        visoButton="link"
                        (click)="clearAllFilters()"
                    >
                        Clear All
                    </button>
                </div>

                <div class="me-1 input-group keyword-filter-group">
                    <input
                        #keywordInputFilter
                        type="text"
                        class="form-control keyword-filter"
                        id="keyword-search"
                        (input)="keywordFilter($event)"
                        placeholder="Filter by keyword..."
                        autocomplete="off"
                    />
                    <button
                        *ngIf="keywordInputFilter.value.length > 0"
                        type="button"
                        class="shrink centered keyword-filter-clear"
                        mat-icon-button
                        (click)="clearKeywordFilter()"
                    >
                        <i class="fa fa-times"></i>
                    </button>
                </div>

                <div class="btn-group me-1" dropdown [insideClick]="true">
                    <button
                        mat-button
                        visoButton="secondary"
                        color="default"
                        class="dropdown-toggle dropdown-filter"
                        [ngClass]="areStatusFiltersApplied ? 'filters-applied' : 'no-filter'"
                        type="button"
                        dropdownToggle
                    >
                        Status
                        <span class="caret"></span>
                    </button>
                    <div *dropdownMenu class="quick-filter dropdown-menu" role="menu">
                        <button class="quick-filter-actions dropdown-item" (click)="toggleAllStatuses()">All</button>
                        <button class="dropdown-item quick-filter-option">
                            <div class="checkbox c-checkbox">
                                <label>
                                    <input
                                        type="checkbox"
                                        [checked]="drStatusFilter.includes(RequestStatus.NOT_ONBOARDED)"
                                        (click)="changeStatusFilter($event)"
                                        [value]="RequestStatus.NOT_ONBOARDED"
                                    />
                                    <span class="fa fa-check"></span> Not Onboarded
                                </label>
                            </div>
                        </button>
                        <button class="dropdown-item quick-filter-option">
                            <div class="checkbox c-checkbox">
                                <label>
                                    <input
                                        type="checkbox"
                                        [checked]="drStatusFilter.includes(RequestStatus.ONBOARDED)"
                                        (click)="changeStatusFilter($event)"
                                        [value]="RequestStatus.ONBOARDED"
                                    />
                                    <span class="fa fa-check"></span> Onboarded
                                </label>
                            </div>
                        </button>
                        <button class="dropdown-item quick-filter-option">
                            <div class="checkbox c-checkbox">
                                <label>
                                    <input
                                        type="checkbox"
                                        [checked]="drStatusFilter.includes(RequestStatus.ARCHIVED)"
                                        (click)="changeStatusFilter($event)"
                                        [value]="RequestStatus.ARCHIVED"
                                    />
                                    <span class="fa fa-check"></span> Archived
                                </label>
                            </div>
                        </button>
                    </div>
                </div>

                <div class="btn-group me-1" dropdown [insideClick]="true">
                    <button
                        mat-button
                        visoButton="secondary"
                        color="default"
                        class="dropdown-toggle dropdown-filter"
                        [ngClass]="areAssessmentPhaseFiltersApplied ? 'filters-applied' : 'no-filter'"
                        type="button"
                        dropdownToggle
                    >
                        {{ 'Assessment Phases' }}
                        <span class="caret"></span>
                    </button>
                    <div *dropdownMenu class="quick-filter dropdown-menu" role="menu">
                        <button class="quick-filter-actions dropdown-item" (click)="toggleAllAssessmentPhases()">
                            All
                        </button>
                        <button class="dropdown-item quick-filter-option">
                            <div class="checkbox c-checkbox">
                                <label>
                                    <input
                                        type="checkbox"
                                        [checked]="assessmentPhaseFilter.includes(AssessmentStatus.NOT_ASSESSED)"
                                        (click)="changeAssessmentPhaseFilter($event)"
                                        value=""
                                    />
                                    <span class="fa fa-check"></span> Not Assessed
                                </label>
                            </div>
                        </button>
                        <button class="dropdown-item quick-filter-option">
                            <div class="checkbox c-checkbox">
                                <label>
                                    <input
                                        type="checkbox"
                                        [checked]="assessmentPhaseFilter.includes(AssessmentStatus.STARTED)"
                                        (click)="changeAssessmentPhaseFilter($event)"
                                        [value]="AssessmentStatus.STARTED"
                                    />
                                    <span class="fa fa-check"></span> Started
                                </label>
                            </div>
                        </button>
                        <button class="dropdown-item quick-filter-option">
                            <div class="checkbox c-checkbox">
                                <label>
                                    <input
                                        type="checkbox"
                                        [checked]="assessmentPhaseFilter.includes(AssessmentStatus.REVIEW_STARTED)"
                                        (click)="changeAssessmentPhaseFilter($event)"
                                        [value]="AssessmentStatus.REVIEW_STARTED"
                                    />
                                    <span class="fa fa-check"></span> Review Started
                                </label>
                            </div>
                        </button>
                        <button class="dropdown-item quick-filter-option">
                            <div class="checkbox c-checkbox">
                                <label>
                                    <input
                                        type="checkbox"
                                        [checked]="
                                            assessmentPhaseFilter.includes(AssessmentStatus.COLLECTING_INFORMATION)
                                        "
                                        (click)="changeAssessmentPhaseFilter($event)"
                                        [value]="AssessmentStatus.COLLECTING_INFORMATION"
                                    />
                                    <span class="fa fa-check"></span> Collecting Information
                                </label>
                            </div>
                        </button>
                        <button class="dropdown-item quick-filter-option">
                            <div class="checkbox c-checkbox">
                                <label>
                                    <input
                                        type="checkbox"
                                        [checked]="assessmentPhaseFilter.includes(AssessmentStatus.COMPLETED)"
                                        (click)="changeAssessmentPhaseFilter($event)"
                                        [value]="AssessmentStatus.COMPLETED"
                                    />
                                    <span class="fa fa-check"></span> Completed
                                </label>
                            </div>
                        </button>
                    </div>
                </div>

                <div class="btn-group me-1" dropdown [insideClick]="true">
                    <button
                        mat-button
                        visoButton="secondary"
                        color="default"
                        class="dropdown-toggle dropdown-filter"
                        [ngClass]="areRiskLevelFiltersApplied ? 'filters-applied' : 'no-filter'"
                        type="button"
                        dropdownToggle
                    >
                        {{ 'Risk Levels' }}
                        <span class="caret"></span>
                    </button>
                    <div *dropdownMenu class="quick-filter risk-filters dropdown-menu" role="menu">
                        <button class="quick-filter-actions dropdown-item" (click)="toggleAllRiskLevels()">All</button>
                        <button class="dropdown-item quick-filter-option">
                            <div class="checkbox c-checkbox">
                                <label>
                                    <input
                                        type="checkbox"
                                        [checked]="riskLevelFilter.includes('NO_CONTEXT')"
                                        (click)="changeRiskLevelFilter($event)"
                                        value="NO_CONTEXT"
                                    />
                                    <span class="fa fa-check"></span>
                                    <app-risk-chip [risk]="Risk.NO_CONTEXT" [condensed]="true"> </app-risk-chip>
                                </label>
                            </div>
                        </button>
                        <button class="dropdown-item quick-filter-option">
                            <div class="checkbox c-checkbox">
                                <label>
                                    <input
                                        type="checkbox"
                                        [checked]="riskLevelFilter.includes('LOW')"
                                        (click)="changeRiskLevelFilter($event)"
                                        value="LOW"
                                    />
                                    <span class="fa fa-check"></span>
                                    <app-risk-chip [risk]="Risk.LOW" [condensed]="true"> </app-risk-chip>
                                </label>
                            </div>
                        </button>
                        <button class="dropdown-item quick-filter-option">
                            <div class="checkbox c-checkbox">
                                <label>
                                    <input
                                        type="checkbox"
                                        [checked]="riskLevelFilter.includes('MEDIUM')"
                                        (click)="changeRiskLevelFilter($event)"
                                        value="MEDIUM"
                                    />
                                    <span class="fa fa-check"></span>
                                    <app-risk-chip [risk]="Risk.MEDIUM" [condensed]="true"> </app-risk-chip>
                                </label>
                            </div>
                        </button>
                        <button class="dropdown-item quick-filter-option">
                            <div class="checkbox c-checkbox">
                                <label>
                                    <input
                                        type="checkbox"
                                        [checked]="riskLevelFilter.includes('HIGH')"
                                        (click)="changeRiskLevelFilter($event)"
                                        value="HIGH"
                                    />
                                    <span class="fa fa-check"></span>
                                    <app-risk-chip [risk]="Risk.HIGH" [condensed]="true"> </app-risk-chip>
                                </label>
                            </div>
                        </button>
                        <button class="dropdown-item quick-filter-option">
                            <div class="checkbox c-checkbox">
                                <label>
                                    <input
                                        type="checkbox"
                                        [checked]="riskLevelFilter.includes('EXTREME')"
                                        (click)="changeRiskLevelFilter($event)"
                                        value="EXTREME"
                                    />
                                    <span class="fa fa-check"></span>
                                    <app-risk-chip [risk]="Risk.EXTREME" [condensed]="true"> </app-risk-chip>
                                </label>
                            </div>
                        </button>
                    </div>
                </div>

                <div class="btn-group me-1" dropdown [insideClick]="true" placement="bottom right">
                    <button
                        *jhiHasNotAuthority="[Roles.Auditor]"
                        mat-button
                        visoButton="secondary"
                        color="default"
                        class="dropdown-toggle dropdown-filter"
                        type="button"
                        [ngClass]="areTagFiltersApplied ? 'filters-applied' : 'no-filter'"
                        dropdownToggle
                    >
                        Filter Tags
                        <span class="caret"></span>
                    </button>
                    <div *dropdownMenu class="quick-filter tags-filters dropdown-menu dropdown-menu-end" role="menu">
                        <app-tag-select
                            [formControl]="selectedTagNamesControl"
                            [createTagDisabled]="true"
                        ></app-tag-select>
                    </div>
                </div>

                <div class="additional-actions d-flex">
                    <button
                        id="additional-filters-button"
                        #additionalFiltersButton
                        mat-button
                        visoButton="text"
                        class="hugh-content"
                        [ngClass]="{ 'additional-filters-applied': additionalFiltersApplied }"
                        type="button"
                        placement="top"
                        ngbTooltip="More filters"
                        (click)="toggleFilterPanel()"
                    >
                        <em class="fa fa-filter"></em>
                    </button>

                    <relationships-additional-filters-panel
                        #additionalFiltersPanel
                        [openPanelButton]="additionalFiltersButton"
                        (filtersApplied)="applyAdditionalFilters($event)"
                    ></relationships-additional-filters-panel>

                    <div class="btn-group" dropdown>
                        <button
                            id="extra-options-button"
                            mat-button
                            visoButton="text"
                            class="hugh-content"
                            type="button"
                            dropdownToggle
                        >
                            <em class="fa fa-ellipsis-v"></em>
                        </button>
                        <div *dropdownMenu class="dropdown-menu dropdown-menu-edge-left" role="menu">
                            <a class="dropdown-item" (click)="openEditColumnsModal()"> Edit columns </a>
                            <a
                                class="dropdown-item"
                                *jhiHasNotAuthority="[Roles.Auditor, Roles.Admin, Roles.Support]"
                                (click)="exportToCsv()"
                            >
                                Export .csv
                            </a>
                        </div>
                    </div>
                </div>
            </section>
        </section>

        <ng-container
            *ngIf="
                !relationshipsLoading &&
                relationshipsLoaded &&
                relationships &&
                relationships.length == 0 &&
                noRelationshipsData &&
                (showList$ | async)
            "
        >
            <hr />
            <section class="card-body">
                <div class="block-center wd-xl">
                    <div class="card card-flat">
                        <div class="card-body">
                            <p class="text-center">Waiting for relationships</p>
                            <ng-container *jhiHasNotAuthority="[Roles.Admin, Roles.Auditor, Roles.Support]">
                                <a
                                    mat-button
                                    visoButton="secondary"
                                    color="default"
                                    class="full-width"
                                    [routerLink]="['/', { outlets: { popup: [addRelationshipRoute] } }]"
                                    >Add a relationship!</a
                                >
                            </ng-container>
                        </div>
                    </div>
                </div>
            </section>
        </ng-container>
    </div>
</div>

<div class="row">
    <div class="col-lg-12" *jhiHasNotAuthority="[Roles.Auditor, Roles.Support]">
        <section class="relationship-bulk-actions" *ngIf="relationshipsSelected.length >= 1">
            <div class="selected-count">{{ relationshipsSelected.length }} selected</div>
            <div>
                <div class="btn-group me-2" dropdown [insideClick]="true" #tagsDropdown="bs-dropdown">
                    <button mat-button visoButton="secondary" type="button" class="mat-button-dropdown" dropdownToggle>
                        <mat-icon iconPositionEnd>arrow_drop_down</mat-icon>
                        Add Tags
                    </button>
                    <div *dropdownMenu class="quick-filter tags-filters dropdown-menu" role="menu">
                        <app-tag-select ngModel #selectedTagsControl="ngModel">
                            <ng-template tag-actions-content>
                                <div class="additional-tag-actions">
                                    <button
                                        mat-button
                                        visoButton="link"
                                        [disabled]="!selectedTagsControl.value?.length"
                                        (click)="
                                            addTagsToRelationships($event, selectedTagsControl, relationshipsSelected);
                                            tagsDropdown.toggle()
                                        "
                                    >
                                        Apply
                                    </button>
                                </div>
                            </ng-template>
                        </app-tag-select>
                    </div>
                </div>
                <button type="button" mat-button visoButton="secondary" class="me-2" (click)="showRelationships()">
                    Offboard
                </button>
                <button type="button" mat-button visoButton="secondary" class="me-2" (click)="archiveRelationships()">
                    Archive
                </button>
                <div class="d-inline-block" [matTooltip]="startAssessmentsButtonTooltipMessage">
                    <button
                        type="button"
                        mat-button
                        visoButton="secondary"
                        class="me-2"
                        [disabled]="!doesCurrentUserHaveAvailableAssessments"
                        (click)="startAssessments()"
                    >
                        Start Assessment
                    </button>
                </div>
                <button type="button" mat-button visoButton="secondary" class="me-2" (click)="cancelAssessments()">
                    Cancel Assessment
                </button>
                <button type="button" mat-button visoButton="secondary" class="me-2" (click)="disableArtifactUpdates()">
                    Disable Artifact Updates
                </button>
                <button type="button" mat-button visoButton="secondary" class="me-2" (click)="enableArtifactUpdates()">
                    Enable Artifact Updates
                </button>
            </div>
        </section>
    </div>
</div>

<div class="row" *ngIf="showList$ | async">
    <div class="col-lg-12">
        <section *ngIf="relationships && relationships.length >= 0 && relationshipsLoaded && !noRelationshipsData">
            <div class="table-responsive">
                <ngx-datatable
                    #relationshipsTable
                    class="bootstrap"
                    [rows]="relationships"
                    [loadingIndicator]="relationshipsLoading"
                    [columnMode]="'force'"
                    [footerHeight]="40"
                    [rowHeight]="35"
                    [headerHeight]="30"
                    [rowClass]="getRowInactiveClass"
                    [scrollbarV]="true"
                    [scrollbarH]="true"
                    [selected]="relationshipsSelected"
                    [selectionType]="SelectionType.checkbox"
                    [externalSorting]="true"
                    (select)="onSelect($event)"
                    (sort)="onSort($event)"
                    [sorts]="tableSort$ | async"
                    [externalPaging]="true"
                    [virtualization]="false"
                    [count]="totalCurrentItems"
                    [offset]="page"
                    [limit]="itemsPerPage"
                    [messages]="customMessages"
                    [observeDims]="true"
                    (resize)="tableResized($event)"
                >
                    <ngx-datatable-column
                        *jhiHasNotAuthority="[Roles.Auditor, Roles.Support]"
                        [width]="40"
                        [sortable]="false"
                        [canAutoResize]="false"
                        [draggable]="false"
                        [resizeable]="false"
                        [frozenLeft]="true"
                    >
                        <ng-template
                            ngx-datatable-header-template
                            let-value="value"
                            let-allRowsSelected="allRowsSelected"
                            let-selectFn="selectFn"
                        >
                            <div
                                class="checkbox c-checkbox float-start select-all"
                                *ngIf="
                                    relationshipsSelected.length === 0 ||
                                    relationshipsSelected.length === activeRelationships.length
                                "
                            >
                                <label>
                                    <input
                                        type="checkbox"
                                        [checked]="allRowsSelected"
                                        (change)="selectFn(!allRowsSelected)"
                                    />
                                    <span class="fa fa-check"></span>
                                </label>
                            </div>

                            <div
                                class="checkbox c-checkbox float-start select-all"
                                *ngIf="
                                    relationshipsSelected.length >= 1 &&
                                    relationshipsSelected.length < activeRelationships.length
                                "
                            >
                                <label>
                                    <input type="checkbox" checked (change)="clearSelection()" />
                                    <span class="fa fa-minus"></span>
                                </label>
                            </div>
                        </ng-template>
                        <ng-template
                            ngx-datatable-cell-template
                            let-value="value"
                            let-row="row"
                            let-isSelected="isSelected"
                            let-onCheckboxChangeFn="onCheckboxChangeFn"
                        >
                            <div
                                *ngIf="row.drStatus !== relationshipArchivedStatus"
                                class="checkbox c-checkbox float-start mt0"
                            >
                                <label>
                                    <input
                                        type="checkbox"
                                        [checked]="isSelected"
                                        (change)="onCheckboxChangeFn($event)"
                                    />
                                    <span class="fa fa-check"></span>
                                </label>
                            </div>
                        </ng-template>
                    </ngx-datatable-column>

                    <!-- Data Columns start -->

                    <ngx-datatable-column
                        name="Relationship"
                        [frozenLeft]="true"
                        cellClass="cell-padding"
                        prop="vendorOrgName.keyword"
                    >
                        <ng-template let-row="row" ngx-datatable-cell-template>
                            <ng-template #vendorPopover let-orgData>
                                <div class="popover-header">
                                    <div
                                        class="popover-icon"
                                        [ngClass]="{ 'white-bg': orgData.vendorFaviconUrl }"
                                        [style.background-image]="
                                            orgData.vendorFaviconUrl ? 'url(' + orgData.vendorFaviconUrl + ')' : ''
                                        "
                                        [style.background-size]="'cover'"
                                    ></div>
                                    <span class="popover-title">
                                        {{ orgData.vendorOrgName }}
                                    </span>
                                </div>
                                <div
                                    class="popover-detail"
                                    *ngIf="orgData.vendorDescription || orgData.vendorUrl || orgData.businessPurpose"
                                >
                                    <div class="popover-detail-label mb-2" *ngIf="orgData.description">Description</div>
                                    <div class="popover-detail-text mb-2" *ngIf="orgData.description">
                                        {{ orgData.description }}
                                    </div>
                                    <div class="popover-link mb-2" *ngIf="orgData.vendorUrl">
                                        <a [externalLink]="orgData.vendorUrl">{{ orgData.vendorUrl }}</a>
                                    </div>
                                    <div class="popover-detail-label mb-2" *ngIf="orgData.businessPurpose">
                                        Business Purpose
                                    </div>
                                    <div class="popover-detail-text" *ngIf="orgData.businessPurpose">
                                        {{ orgData.businessPurpose }}
                                    </div>
                                </div>
                            </ng-template>
                            <div
                                *ngIf="row.vendorOrgName"
                                class="vendor-name-text"
                                [appPopover]="vendorPopover"
                                [popoverHostSelector]="'.datatable-body-cell'"
                                [popoverContext]="row"
                                [popoverMaxWidth]="300"
                            >
                                <div
                                    class="vendor-logo-icon"
                                    [ngClass]="{ 'white-bg': row.vendorFaviconUrl }"
                                    [style.background-image]="
                                        row.vendorFaviconUrl ? 'url(' + row.vendorFaviconUrl + ')' : ''
                                    "
                                    [style.background-size]="'cover'"
                                ></div>
                                <ng-container *ngIf="row.drStatus !== RequestStatus.ARCHIVED; else archived">
                                    <a
                                        [routerLink]="['', 'requests', row.id]"
                                        data-pw="relationshipListItemVendorLink"
                                        >{{ row.vendorOrgName }}</a
                                    >
                                </ng-container>
                                <ng-template #archived>
                                    <a>{{ row.vendorOrgName }}</a>
                                </ng-template>
                            </div>
                        </ng-template>
                    </ngx-datatable-column>
                    <ng-container *jhiHasAnyAuthority="[Roles.Auditor, Roles.Support]">
                        <ngx-datatable-column
                            name="Client"
                            prop="customerOrgName"
                            [width]="90"
                            [cellClass]="getColumnClass('Client')"
                            [headerClass]="getColumnClass('Client')"
                            *ngIf="getColumnVisibility('Client')"
                        >
                            <ng-template let-row="row" ngx-datatable-cell-template
                                >{{ row.customerOrgName }}
                            </ng-template>
                        </ngx-datatable-column>
                    </ng-container>
                    <ngx-datatable-column
                        name="Status"
                        prop="drStatus"
                        [width]="90"
                        [cellClass]="getDrStatusPhaseClass"
                        [headerClass]="getColumnClass('Status')"
                        *ngIf="getColumnVisibility('Status')"
                    >
                        <ng-template let-row="row" ngx-datatable-cell-template>
                            {{ row.drStatus | pascalCase }}
                        </ng-template>
                    </ngx-datatable-column>
                    <ngx-datatable-column
                        name="Risk"
                        prop="score"
                        [width]="90"
                        [cellClass]="'cell-centered ' + getColumnClass('Risk')"
                        [headerClass]="getColumnClass('Risk')"
                        *ngIf="getColumnVisibility('Risk')"
                    >
                        <ng-template let-row="row" ngx-datatable-cell-template>
                            <app-risk-chip
                                placement="right"
                                container="body"
                                [ngbTooltip]="
                                    !row.latestRiskAssessmentLegacy && row.transitional
                                        ? 'The final risk is still being calculated since the assessment is still in progress'
                                        : ''
                                "
                                [risk]="row.residualRisk"
                                [riskIsTransitional]="!row.latestRiskAssessmentLegacy && row.transitional"
                                [condensed]="true"
                            >
                            </app-risk-chip>
                        </ng-template>
                    </ngx-datatable-column>
                    <ngx-datatable-column
                        name="Residual Risk Score"
                        [width]="90"
                        prop="score"
                        [cellClass]="getColumnClass('Residual Risk Score')"
                        [headerClass]="getColumnClass('Residual Risk Score')"
                        *ngIf="getColumnVisibility('Residual Risk Score')"
                    >
                        <ng-template let-score="value" ngx-datatable-cell-template>
                            {{ score * 100 | number: '1.2-2' }}%
                        </ng-template>
                    </ngx-datatable-column>
                    <ngx-datatable-column
                        name="Inherent Risk"
                        prop="inherentRiskScore"
                        [width]="90"
                        [cellClass]="'cell-centered ' + getColumnClass('Inherent Risk')"
                        [headerClass]="getColumnClass('Inherent Risk')"
                        *ngIf="getColumnVisibility('Inherent Risk')"
                    >
                        <ng-template let-row="row" ngx-datatable-cell-template>
                            <app-risk-chip
                                placement="right"
                                container="body"
                                [ngbTooltip]="getInherentRiskTooltipText(row)"
                                [risk]="row.inherentRisk"
                                [riskIsTransitional]="false"
                                [condensed]="true"
                            >
                            </app-risk-chip>
                        </ng-template>
                    </ngx-datatable-column>
                    <ngx-datatable-column
                        name="Inherent Risk Score"
                        [width]="90"
                        prop="inherentRiskScore"
                        [cellClass]="getColumnClass('Inherent Risk Score')"
                        [headerClass]="getColumnClass('Inherent Risk Score')"
                        *ngIf="getColumnVisibility('Inherent Risk Score')"
                    >
                        <ng-template let-inherentRiskScore="value" ngx-datatable-cell-template>
                            {{ inherentRiskScore * 100 | number: '1.2-2' }}%
                        </ng-template>
                    </ngx-datatable-column>
                    <ngx-datatable-column
                        name="Residual Risk"
                        prop="score"
                        [width]="90"
                        [cellClass]="'cell-centered ' + getColumnClass('Residual Risk')"
                        [headerClass]="getColumnClass('Residual Risk')"
                        *ngIf="getColumnVisibility('Residual Risk')"
                    >
                        <ng-template let-row="row" ngx-datatable-cell-template>
                            <app-risk-chip
                                *ngIf="
                                    !!row.latestNonTransitionalRiskAssessmentResidualRisk;
                                    else noNonTransitionalRiskAssessment
                                "
                                placement="right"
                                container="body"
                                [ngbTooltip]="
                                    getResidualRiskTooltipText(
                                        row,
                                        row.latestNonTransitionalRiskAssessmentResidualRisk
                                            | riskLevel
                                            | async
                                            | titlecase
                                    )
                                "
                                [risk]="row.latestNonTransitionalRiskAssessmentResidualRisk"
                                [riskIsTransitional]="!row.latestRiskAssessmentLegacy && row.transitional"
                                [condensed]="true"
                            >
                            </app-risk-chip>
                            <ng-template #noNonTransitionalRiskAssessment>-</ng-template>
                        </ng-template>
                    </ngx-datatable-column>
                    <ngx-datatable-column
                        name="Last Updated"
                        [width]="100"
                        prop="updatedDate"
                        [cellClass]="getColumnClass('Last Updated')"
                        [headerClass]="getColumnClass('Last Updated')"
                        *ngIf="getColumnVisibility('Last Updated')"
                    >
                        <ng-template let-value="value" ngx-datatable-cell-template
                            >{{ value | date: 'mediumDate' }}
                        </ng-template>
                    </ngx-datatable-column>
                    <ngx-datatable-column
                        name="Assessment Phase"
                        [width]="120"
                        prop="assessmentStatusOrdinal"
                        [cellClass]="getAssessmentPhaseClass"
                        [headerClass]="getColumnClass('Assessment Phase')"
                        *ngIf="getColumnVisibility('Assessment Phase')"
                    >
                        <ng-template let-row="row" ngx-datatable-cell-template>
                            <div
                                class="tooltip-wrapper"
                                placement="right"
                                container="body"
                                [ngbTooltip]="row.assessmentPhaseTooltipMessage"
                            >
                                {{ row.assessmentStatus }}
                            </div>
                        </ng-template>
                    </ngx-datatable-column>
                    <ngx-datatable-column
                        name="Recommendations"
                        [width]="135"
                        prop="recommendationCount"
                        [cellClass]="getColumnClass('Recommendations')"
                        [headerClass]="getColumnClass('Recommendations')"
                        *ngIf="getColumnVisibility('Recommendations')"
                    >
                        <ng-template let-row="row" ngx-datatable-cell-template>
                            {{ row.recommendationCount }}
                        </ng-template>
                    </ngx-datatable-column>
                    <ngx-datatable-column
                        name="Remediation Requested"
                        [width]="135"
                        prop="remediationRequested"
                        [cellClass]="getColumnClass('Remediation Requested')"
                        [headerClass]="getColumnClass('Remediation Requested')"
                        *ngIf="getColumnVisibility('Remediation Requested')"
                    >
                        <ng-template let-remediationRequested="value" ngx-datatable-cell-template>
                            {{ remediationRequested ? 'Yes' : 'No' }}
                        </ng-template>
                    </ngx-datatable-column>
                    <ngx-datatable-column
                        name="Requested"
                        [width]="90"
                        prop="assessmentCreatedDate"
                        [cellClass]="getColumnClass('Requested')"
                        [headerClass]="getColumnClass('Requested')"
                        *ngIf="getColumnVisibility('Requested')"
                    >
                        <ng-template let-row="row" ngx-datatable-cell-template>
                            {{ row.assessmentCreatedDate ? (row.assessmentCreatedDate | date: 'mediumDate') : '-' }}
                        </ng-template>
                    </ngx-datatable-column>
                    <ngx-datatable-column
                        name="Phase Date"
                        [width]="90"
                        prop="assessmentStatusDate"
                        [cellClass]="getColumnClass('Phase Date')"
                        [headerClass]="getColumnClass('Phase Date')"
                        *ngIf="getColumnVisibility('Phase Date')"
                    >
                        <ng-template let-row="row" ngx-datatable-cell-template>
                            {{ row.assessmentStatusDate ? (row.assessmentStatusDate | date: 'mediumDate') : '-' }}
                        </ng-template>
                    </ngx-datatable-column>
                    <ngx-datatable-column
                        name="Recertification Date"
                        [width]="130"
                        prop="recertificationDate"
                        [cellClass]="getColumnClass('Recertification Date')"
                        [headerClass]="getColumnClass('Recertification Date')"
                        *ngIf="getColumnVisibility('Recertification Date')"
                    >
                        <ng-template let-row="row" ngx-datatable-cell-template>
                            <span
                                [ngbTooltip]="getRelationshipRecertificationTooltipMessage(row)"
                                placement="right"
                                class="pe-1"
                            >
                                <em
                                    *ngIf="isRelationshipUpForRecertification(row)"
                                    [ngClass]="
                                        doesRelationshipHaveInProgressAssessment(row)
                                            ? 'fa fa-sync text-muted me-2'
                                            : 'fa fa-exclamation-circle text-danger me-2'
                                    "
                                ></em>
                                {{
                                    row.recertificationDate && row.drStatus == RequestStatus.ONBOARDED
                                        ? (row.recertificationDate | date: 'mediumDate')
                                        : '-'
                                }}
                            </span>
                        </ng-template>
                    </ngx-datatable-column>

                    <ngx-datatable-column
                        name="Recertification Type"
                        [width]="130"
                        prop="recertificationType"
                        [cellClass]="getColumnClass('Recertification Type')"
                        [headerClass]="getColumnClass('Recertification Type')"
                        *ngIf="getColumnVisibility('Recertification Type')"
                    >
                        <ng-template let-row="row" ngx-datatable-cell-template>
                            {{
                                row.recertificationType && row.drStatus == RequestStatus.ONBOARDED
                                    ? getRecertificationType(row.recertificationType)
                                    : '-'
                            }}
                        </ng-template>
                    </ngx-datatable-column>

                    <ngx-datatable-column
                        name="Artifact Update Type"
                        [width]="130"
                        prop="artifactUpdateType"
                        [cellClass]="getArtifactUpdateClass"
                        [headerClass]="getColumnClass('Artifact Update Type')"
                        *ngIf="getColumnVisibility('Artifact Update Type')"
                    >
                        <ng-template let-row="row" ngx-datatable-cell-template>
                            {{
                                row.recertificationType && row.drStatus == RequestStatus.ONBOARDED
                                    ? getArtifactUpdateType(row.artifactUpdateType)
                                    : '-'
                            }}
                        </ng-template>
                    </ngx-datatable-column>

                    <ngx-datatable-column
                        name="Business Unit"
                        [width]="110"
                        prop="businessUnitName"
                        [cellClass]="getColumnClass('Business Unit')"
                        [headerClass]="getColumnClass('Business Unit')"
                        *ngIf="getColumnVisibility('Business Unit')"
                    >
                        <ng-template let-row="row" ngx-datatable-cell-template>{{ row.businessUnitName }} </ng-template>
                    </ngx-datatable-column>

                    <ngx-datatable-column
                        name="Business Owner Email"
                        [width]="110"
                        prop="businessOwnerEmail"
                        [cellClass]="getColumnClass('Business Owner Email')"
                        [headerClass]="getColumnClass('Business Owner Email')"
                        *ngIf="getColumnVisibility('Business Owner Email')"
                    >
                        <ng-template let-row="row" ngx-datatable-cell-template>
                            <div *ngIf="row.businessOwnerId" class="creator-name-text">
                                {{ row.businessOwnerEmail }}
                            </div>
                        </ng-template>
                    </ngx-datatable-column>

                    <ngx-datatable-column
                        name="Business Owner Name"
                        [width]="110"
                        prop="businessOwnerFirstName,businessOwnerLastName"
                        [cellClass]="getColumnClass('Business Owner Name')"
                        [headerClass]="getColumnClass('Business Owner Name')"
                        *ngIf="getColumnVisibility('Business Owner Name')"
                    >
                        <ng-template let-row="row" ngx-datatable-cell-template>
                            <div *ngIf="row.businessOwnerId" class="creator-name-text">
                                {{ row.businessOwnerFirstName }} {{ row.businessOwnerLastName }}
                            </div>
                        </ng-template>
                    </ngx-datatable-column>

                    <ngx-datatable-column
                        name="Third Party Contact Name"
                        [width]="110"
                        prop="primaryContactFirstName,primaryContactLastName"
                        [cellClass]="getColumnClass('Third Party Contact Name')"
                        [headerClass]="getColumnClass('Third Party Contact Name')"
                        *ngIf="getColumnVisibility('Third Party Contact Name')"
                    >
                        <ng-template let-row="row" ngx-datatable-cell-template>
                            {{
                                row.primaryContactId
                                    ? row.primaryContactFirstName + ' ' + row.primaryContactLastName
                                    : '-'
                            }}
                        </ng-template>
                    </ngx-datatable-column>

                    <ngx-datatable-column
                        name="Third Party Contact Email"
                        [width]="110"
                        prop="primaryContactEmail"
                        [cellClass]="getColumnClass('Third Party Contact Email')"
                        [headerClass]="getColumnClass('Third Party Contact Email')"
                        *ngIf="getColumnVisibility('Third Party Contact Email')"
                    >
                        <ng-template let-row="row" ngx-datatable-cell-template>
                            <div class="primary-contact-text">
                                {{ row.primaryContactId ? row.primaryContactEmail : '-' }}
                            </div>
                        </ng-template>
                    </ngx-datatable-column>

                    <ngx-datatable-column
                        name="Created By"
                        [width]="110"
                        prop="creatorFirstName"
                        [cellClass]="getColumnClass('Created By')"
                        [headerClass]="getColumnClass('Created By')"
                        *ngIf="getColumnVisibility('Created By')"
                    >
                        <ng-template let-row="row" ngx-datatable-cell-template>
                            <div *ngIf="row.creatorId" class="creator-name-text">
                                {{ row.creatorFirstName }} {{ row.creatorLastName }}
                            </div>
                        </ng-template>
                    </ngx-datatable-column>
                    <ngx-datatable-column
                        name="Created On"
                        [width]="90"
                        prop="createdDate"
                        [cellClass]="getColumnClass('Created On')"
                        [headerClass]="getColumnClass('Created On')"
                        *ngIf="getColumnVisibility('Created On')"
                    >
                        <ng-template let-value="value" ngx-datatable-cell-template
                            >{{ value | date: 'mediumDate' }}
                        </ng-template>
                    </ngx-datatable-column>
                    <ngx-datatable-column
                        name="Tags"
                        [width]="150"
                        prop="tags"
                        [cellClass]="'tags-cell ' + getColumnClass('Tags')"
                        [headerClass]="getColumnClass('Tags')"
                        *ngIf="getColumnVisibility('Tags')"
                    >
                        <ng-template let-value="value" ngx-datatable-cell-template>
                            <div *ngIf="value.length === 0">-</div>
                            <ng-container *collapseCellItems="value; width: tagsColumnWidth; let currentTag">
                                <span [tagColor]="currentTag?.color">{{ currentTag?.name }}</span>
                            </ng-container>
                        </ng-template>
                    </ngx-datatable-column>

                    <!-- Data Columns end -->

                    <ngx-datatable-column
                        [width]="40"
                        *jhiHasNotAuthority="[Roles.Auditor, Roles.Support, Roles.OrgUser]"
                        cellClass="order-50 text-center bg-white"
                        headerClass="order-50"
                        [resizable]="false"
                        [sortable]="false"
                        [canAutoResize]="false"
                        [draggable]="false"
                        [frozenRight]="false"
                    >
                        <ng-template let-row="row" ngx-datatable-cell-template>
                            <div class="additional-actions" placement="bottom left" dropdown container="body">
                                <button
                                    id="button-container"
                                    dropdownToggle
                                    type="button"
                                    class="btn-icon float-end"
                                    aria-controls="dropdown-container"
                                >
                                    <em class="fa fa-ellipsis-v"></em>
                                </button>
                                <ul
                                    id="dropdown-container"
                                    *dropdownMenu
                                    class="dropdown-menu dropdown-menu-start translate-x"
                                    aria-labelledby="button-container"
                                    style="--data-translate-value: -160px"
                                >
                                    <li
                                        [ngClass]="{
                                            hidden:
                                                doesRelationshipHaveInProgressAssessment(row) ||
                                                row.drStatus === relationshipArchivedStatus,
                                            disabled: !doesCurrentUserHaveAvailableAssessments,
                                        }"
                                    >
                                        <a class="dropdown-item" (click)="startAssessments([row.id])"
                                            >Start Assessment</a
                                        >
                                    </li>
                                    <li
                                        [ngClass]="{
                                            hidden:
                                                !doesRelationshipHaveInProgressAssessment(row) ||
                                                row.drStatus === relationshipArchivedStatus,
                                        }"
                                    >
                                        <a class="dropdown-item" (click)="cancelAssessments([row.id])"
                                            >Cancel Assessment</a
                                        >
                                    </li>
                                    <ng-container *ngIf="row.drStatus === relationshipArchivedStatus; else nonArchived">
                                        <li>
                                            <a class="dropdown-item" (click)="showRelationships([row.id])">Restore</a>
                                        </li>
                                    </ng-container>
                                    <ng-template #nonArchived>
                                        <li [ngClass]="{ hidden: row.drStatus === relationshipNotOnboardedStatus }">
                                            <a class="dropdown-item" (click)="showRelationships([row.id])">
                                                Offboard
                                            </a>
                                        </li>
                                        <li [ngClass]="{ hidden: row.drStatus === relationshipOnboardedStatus }">
                                            <a class="dropdown-item" (click)="archiveRelationships([row.id])">
                                                Archive
                                            </a>
                                        </li>
                                    </ng-template>
                                </ul>
                            </div>
                        </ng-template>
                    </ngx-datatable-column>

                    <ngx-datatable-footer>
                        <ng-template
                            ngx-datatable-footer-template
                            let-page="page"
                            let-rowCount="rowCount"
                            let-pageSize="pageSize"
                            let-selectedCount="selectedCount"
                            let-curPage="curPage"
                            let-offset="offset"
                        >
                            <datatable-pager
                                [pagerLeftArrowIcon]="'datatable-icon-left'"
                                [pagerRightArrowIcon]="'datatable-icon-right'"
                                [pagerPreviousIcon]="'datatable-icon-prev'"
                                [pagerNextIcon]="'datatable-icon-skip'"
                                [page]="curPage"
                                [size]="pageSize"
                                [count]="rowCount"
                                [hidden]="!(rowCount / pageSize > 1)"
                                (change)="setCurrentPage($event)"
                            ></datatable-pager>
                            <div
                                ngbDropdown
                                class="d-inline-block ms-auto"
                                placement="top-end"
                                [hidden]="rowCount < 20"
                            >
                                <button mat-button visoButton="secondary" ngbDropdownToggle>
                                    {{ rowLabel }}
                                </button>
                                <div ngbDropdownMenu>
                                    <div *ngFor="let op of rowOptions">
                                        <button ngbDropdownItem (click)="setPagination(op.value)">
                                            {{ op.value }}
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </ng-template>
                    </ngx-datatable-footer>
                </ngx-datatable>
            </div>
        </section>
    </div>
</div>
<div class="row" *ngIf="showGraph$ | async">
    <div class="col">
        <app-risk-network
            [graphNodes]="riskNetworkGraphNodes$ | async"
            [currentAccount]="currentAccount$ | async"
            [networkExposureOrgId]="networkExposureOrgId"
            [triggerShow4thParties]="isNthPartiesFilterApplied || !!networkExposureOrgId"
            (selectedVendorId)="applyVendorIdFilter($event)"
        ></app-risk-network>
    </div>
</div>
