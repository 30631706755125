<ngx-guided-tour doneText="Finish" />
@if (layout$ | async; as layoutType) {
    <app-header
        class="topnavbar-wrapper"
        *ngIf="layoutType === LayoutType.Full"
        (helpCenterOpened)="helpDocsDrawer.toggle()"
        (helpCenterClosed)="helpDocsDrawer.close()"
    ></app-header>
    <app-basic-header class="topnavbar-wrapper" *ngIf="layoutType === LayoutType.Basic"></app-basic-header>
    <app-assessment-header class="topnavbar-wrapper" *ngIf="layoutType === LayoutType.Assessment" />
    <mat-drawer-container class="h-100" autosize>
        <mat-drawer class="help-docs-drawer" #helpDocsDrawer mode="side" position="end" resizableDrawer>
            <app-help-docs [drawer]="helpDocsDrawer" />
        </mat-drawer>
        <mat-drawer-content>
            <div class="wrapper">
                <app-sidebar class="aside-container" *ngIf="layoutType === LayoutType.Full"></app-sidebar>
                <main
                    class="section-container"
                    [ngClass]="{
                        'ms-0': layoutType === LayoutType.Basic,
                        'm-0 no-footer': layoutType === LayoutType.Blank,
                    }"
                >
                    <div class="content-wrapper" [ngClass]="{ 'p-0 minh-100vh': layoutType === LayoutType.Blank }">
                        <router-outlet></router-outlet>
                        <router-outlet name="popup"></router-outlet>
                    </div>
                </main>
            </div>
        </mat-drawer-content>
    </mat-drawer-container>
}
