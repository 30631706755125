import {
    AfterViewInit,
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    Input,
    OnDestroy,
    OnInit,
    Output,
    QueryList,
    ViewChildren,
} from '@angular/core';
import { Actions, ofType } from '@ngrx/effects';
import { MatExpansionPanel } from '@angular/material/expansion';
import { BehaviorSubject, Subject, merge } from 'rxjs';
import { mergeMap, startWith, switchMap, take, takeUntil } from 'rxjs/operators';
import { AuditReportTypeName } from '../../../../../entities/audit-report';
import {
    ArtifactType,
    ControlValidationDetectionType,
    ControlValidationDetectionTypeLabels,
} from '../../../../../entities/artifact';
import {
    ControlDomainControlValidationDetectionWithArtifact,
    ControlsWithDetections,
    GroupedSecurityControlDomain,
    PopulatedControlDomainControlAssessment,
    SecurityControlDomainGroupStatus,
    SecurityControlDomainGroupStatusLabels,
} from '../../../../../entities/relationship/models/security-control-domain';
import { VisoUserRole } from '../../../../../entities/viso-user';
import {
    createFollowUpQuestionnaireRequest,
    createFollowUpQuestionnaireRequestSuccess,
    getRequestAssessmentsRequestSuccess,
} from '../../../../../routes/request/redux/actions/assessments.actions';
import { ControlDetectionToggle } from '../../../../../routes/request/models';
import { FrameworkMappingType } from '@entities/framework/models/framework-mapping.model';

@Component({
    selector: 'app-control-group',
    templateUrl: './control-group.component.html',
    styleUrls: ['./control-group.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ControlGroupComponent implements OnInit, AfterViewInit, OnDestroy {
    @Input()
    group: GroupedSecurityControlDomain;

    @Input()
    noAssessment: boolean;

    @Input()
    latestAssessmentInProgress: boolean;

    @Input()
    latestAssessmentCompleted: boolean;

    @Input()
    missingInformationWasRequested: boolean;

    @Input()
    latestAssessmentFollowUpQuestionnaireCreatedDate: Date;

    @Input()
    assessmentSentToEmail: string;

    @Input()
    isLatestAssessmentNonDocumentsOnly: boolean;

    @Input()
    isDisabled: boolean;

    @Input()
    isOutOfScope: boolean;

    @Input()
    onExport: boolean;

    @Input()
    frameworkType: FrameworkMappingType;

    @Output()
    accordionToggled = new EventEmitter<ControlDetectionToggle>();

    @Output()
    requestInformation = new EventEmitter<void>();

    @ViewChildren(MatExpansionPanel)
    private _accordionComponents: QueryList<MatExpansionPanel>;

    ControlValidationDetectionTypeLabels = ControlValidationDetectionTypeLabels;
    AuditReportType = AuditReportTypeName;
    ArtifactType = ArtifactType;
    SecurityControlDomainGroupStatus = SecurityControlDomainGroupStatus;
    DetectionType = ControlValidationDetectionType;
    Roles = VisoUserRole;

    requestingInformation$ = new BehaviorSubject<boolean>(false);
    openedAccordions = new Map<number, boolean>();
    allOpened = false;

    private _unsub$ = new Subject<void>();

    constructor(private _actions$: Actions) {}

    get showRequestInformation(): boolean {
        return (
            this.groupControlValidationStatus === SecurityControlDomainGroupStatus.MISSING_INFORMATION &&
            this.isLatestAssessmentNonDocumentsOnly &&
            !!this.assessmentSentToEmail
        );
    }

    get groupName(): string {
        return (
            (this.group?.controlValidationStatus &&
                SecurityControlDomainGroupStatusLabels[this.group.controlValidationStatus]) ||
            ''
        );
    }

    get groupControlValidationStatus(): SecurityControlDomainGroupStatus {
        return this.group?.controlValidationStatus;
    }

    get controlDomainAssessments(): PopulatedControlDomainControlAssessment[] {
        return this.group?.controlDomainAssessments || [];
    }

    ngOnInit(): void {
        this._actions$.pipe(ofType(createFollowUpQuestionnaireRequest), takeUntil(this._unsub$)).subscribe(() => {
            this.requestingInformation$.next(true);
        });

        this._actions$
            .pipe(
                ofType(createFollowUpQuestionnaireRequestSuccess),
                mergeMap(() => this._actions$.pipe(ofType(getRequestAssessmentsRequestSuccess), take(1))),
                takeUntil(this._unsub$),
            )
            .subscribe(() => {
                this.requestingInformation$.next(false);
            });
    }

    ngAfterViewInit(): void {
        this._accordionComponents.changes
            .pipe(
                startWith(true),
                switchMap(() => merge(...this._accordionComponents.map((accordion) => accordion.expandedChange))),
                takeUntil(this._unsub$),
            )
            .subscribe(() => {
                this.allOpened = this._accordionComponents.toArray().every((accordion) => accordion.expanded);
            });
    }

    triggerAccordionToggled(
        opened: boolean,
        { controlDomainId: controlId, controlName }: PopulatedControlDomainControlAssessment,
    ): void {
        this.handleAccordionOpenedState(controlId, opened);
        this.accordionToggled.emit({
            opened,
            controlId,
            controlName,
        });
    }

    isDetectionAllowed(detection: ControlDomainControlValidationDetectionWithArtifact): boolean {
        return AuditReportTypeName[detection.auditReportType] !== AuditReportTypeName.SUPERSEDED;
    }

    confirmRequestInformation(): void {
        this.requestInformation.emit();
    }

    toggleAll() {
        if (this.allOpened) {
            this.closeAll();
        } else {
            this.openAll();
        }
    }

    ngOnDestroy(): void {
        this._unsub$.next();
    }

    isAccordionOpened(controlDomainId: number): boolean {
        if (this.onExport) {
            return true;
        }
        return !!this.openedAccordions.get(controlDomainId);
    }

    trackByControlId(index: number, item: ControlsWithDetections) {
        return item.controlId;
    }

    trackById(index: number, item: ControlDomainControlValidationDetectionWithArtifact) {
        return item.id;
    }

    trackByControlDomainId(index: number, controlDomainAssessment: PopulatedControlDomainControlAssessment) {
        return controlDomainAssessment.controlDomainId;
    }

    private openAll() {
        this._accordionComponents?.forEach((accordion) => (accordion.expanded = true));
    }

    private closeAll() {
        this._accordionComponents?.forEach((accordion) => (accordion.expanded = false));
    }

    private handleAccordionOpenedState(controlDomainId: number, openedState: boolean): void {
        this.openedAccordions.set(controlDomainId, openedState);
    }
}
